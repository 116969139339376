import { useRef } from "react";
import { ScrollArea } from "@/components/scroll-area";
import {
  PaymentPayload,
  TransactionMovement,
  TransactionStatus,
} from "@/types";
import {
  descriptionStyles,
  formatBankSlip,
  formatCurrency,
  formatDocument,
  usePrint,
} from "@/utils";
import { formatDate } from "@/utils/date";
import { Button } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { VerticalLogo } from "../vertical-logo";

type PaymentStatusProps = {
  status: TransactionStatus;
  movement: TransactionMovement;
};

const getPaymentStatusLabel = ({ status, movement }: PaymentStatusProps) => {
  const statusMap: Partial<Record<TransactionStatus, string>> = {
    PAID: "Realizado",
    DENIED: "Negado",
    PENDING: "Pendente",
  };

  const isReversal = movement === "IN" && status === "NOT_EXECUTED";

  if (isReversal) return "Estornado";

  return statusMap[status] || statusMap.PAID;
};

export const PaymentReceipt = ({
  Description,
  DueDate,
  TransactionDate,
  Amount,
  Fine,
  Interest,
  Discount,
  BarCodeNumber,
  Payer,
  TransactionCode,
  Status,
  movement,
}: PaymentPayload) => {
  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = usePrint({
    content: () => componentRef.current,
  });

  const isPending = Status === "PENDING";

  return (
    <div className="flex h-[100svh] flex-col overflow-hidden pt-6" id="receipt">
      <h4 className="font-inter -mt-2 ml-4 pb-4 text-2xl font-bold text-neutral-darkest">
        Comprovante
      </h4>
      <div
        className="mb-6 flex w-full flex-col items-center overflow-hidden"
        ref={componentRef}
      >
        <ScrollArea className="flex h-full w-full flex-col px-6" id="infos">
          <VerticalLogo />
          <div className="flex w-full flex-col items-center overflow-hidden">
            <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
              Pagamento de boleto
            </h5>
            <h5 className="mb-6 font-base text-sm/md font-bold text-primary-main">
              {getPaymentStatusLabel({ status: Status, movement })}
            </h5>
            <div className="flex h-full w-full flex-col overflow-hidden">
              {Description && (
                <>
                  <hr className="h-[1px] w-full bg-neutral-light" />
                  <dl className="my-4 h-full w-full">
                    <dt className={definitionTerm()}>Mensagem</dt>
                    <dd
                      className={twMerge(definitionDetail(), "mt-1 text-start")}
                    >
                      {Description}
                    </dd>
                  </dl>
                </>
              )}
              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <div className="col-span-2">
                  <dt className={definitionTerm()}>Código de barras</dt>
                  <dd
                    className={twMerge(
                      definitionDetail(),
                      "break-all text-start",
                    )}
                  >
                    {formatBankSlip(BarCodeNumber)}
                  </dd>
                </div>
                <dt className={definitionTerm()}>Data de vencimento</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatDate(DueDate)}
                </dd>
                <dt className={definitionTerm()}>Data de pagamento</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatDate(TransactionDate)}
                </dd>
                <dt className={definitionTerm()}>Valor</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatCurrency(Amount)}
                </dd>
                <dt className={definitionTerm()}>Multa</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatCurrency(Fine)}
                </dd>
                <dt className={definitionTerm()}>Juros</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatCurrency(Interest)}
                </dd>
                <dt className={definitionTerm()}>Desconto</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatCurrency(Discount)}
                </dd>
              </dl>

              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Pago por</dt>
                <dd className={twMerge(definitionDetail())}>
                  {Payer?.Actual.Name}
                </dd>
              </dl>
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Documento</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatDocument(Payer?.Actual?.Document || "")}
                </dd>
              </dl>
              <hr className="h-[1px] w-full bg-neutral-light" />
              <div className="col-span-2">
                <dl className="mt-4 w-full">
                  <dt className={definitionTerm()}>Id da Operação</dt>
                  <dd
                    className={twMerge(
                      definitionDetail(),
                      "break-all text-start",
                    )}
                  >
                    {TransactionCode}
                  </dd>
                </dl>
              </div>
              {isPending && (
                <>
                  <hr className="my-4 h-[1px] w-full bg-neutral-light" />
                  <div className="col-span-2 w-full font-base text-x2s/5">
                    <span className="text-status-warning-dark">
                      Importante:
                    </span>
                    <span className="text-neutral-dark">
                      {" "}
                      Seu pagamento já foi enviado ao banco. O comprovante ainda
                      está sendo processado para controle interno das
                      transações.
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </ScrollArea>
      </div>
      <Button.Root className="m-6 mt-auto" onClick={handlePrint}>
        <Button.Slot name="GeralPrint" />
        Imprimir comprovante
      </Button.Root>
    </div>
  );
};
