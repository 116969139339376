import { ReceiptRoot } from "@/components/receipt-root";
import { ParcelProps } from "@/types";
import { routes } from "@/utils";
import { Route, Routes as Switch } from "react-router-dom";

export const Routes = (props: ParcelProps) => {
  return (
    <Switch>
      <Route path={routes.receiptDetail} element={<ReceiptRoot {...props} />} />
      {props.receiptId && (
        <Route path="*" element={<ReceiptRoot {...props} />} />
      )}
    </Switch>
  );
};
