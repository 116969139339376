import { GetReceiptRequest } from "@/types";
import { ReceiptDetail } from "@/types/detail";
import { API, queries } from "@/utils";
import { useSuspenseQuery } from "@tanstack/react-query";

const { receiptKeys } = queries;

const getReceiptDetail = async ({
  receiptType,
  receiptId,
  receiptOrigin = "transfers",
}: GetReceiptRequest) => {
  try {
    const type = receiptType.toLowerCase();

    const getParams = () => {
      if (receiptOrigin === "statements" && type === "ted") {
        return {
          type,
          code: receiptId,
        };
      }

      if (["p2p", "pix", "payment"].includes(type)) {
        return {
          type,
          code: receiptId,
        };
      }

      return {
        id: receiptId,
        type,
      };
    };

    const response = await API.statements.get<ReceiptDetail>("/Detail", {
      params: getParams(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const useGetReceipt = (params: GetReceiptRequest) => {
  return useSuspenseQuery({
    queryKey: receiptKeys.getReceiptDetail(
      params.receiptId,
      params.receiptType,
      params.receiptOrigin,
    ),

    queryFn: () => getReceiptDetail(params),
  });
};
