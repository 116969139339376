import { BankAccountType } from "@/types";
import {
  formatBoleto,
  formatCNPJ,
  formatCPF,
  isValidBoleto,
  isValidCNPJ,
  isValidCPF,
} from "@brazilian-utils/brazilian-utils";

export const formatDocument = (document: string) => {
  if (isValidCPF) return formatCPF(document);
  if (isValidCNPJ) return formatCNPJ(document);

  return document;
};

export const formatBankSlip = (barCode: string) => {
  if (isValidBoleto(barCode)) return formatBoleto(barCode);

  return barCode;
};
export const bankAccountTypeDictionary: Record<BankAccountType, string> = {
  CC: "Conta Corrente",
  PA: "Conta Pagamento",
  SA: "Conta Poupança",
};
