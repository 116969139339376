import { VerticalLogo } from "../vertical-logo";

export const NotFound = () => {
  return (
    <div className="flex h-[100svh] flex-col overflow-hidden pt-6">
      <div className="mb-6 flex w-full flex-col items-center overflow-hidden">
        <VerticalLogo />

        <div className="flex w-full flex-col items-center overflow-hidden">
          <span>Comprovante não encontrado</span>
        </div>
      </div>
    </div>
  );
};
