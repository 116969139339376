import { useRef } from "react";
import { ScrollArea } from "@/components/scroll-area";
import { PixPayload } from "@/types";
import { descriptionStyles, formatCurrency, usePrint } from "@/utils";
import { formatDate } from "@/utils/date";
import { Button } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { VerticalLogo } from "../vertical-logo";

export const PixReceipt = (props: PixPayload) => {
  const {
    Amount,
    TransactionDate,
    TransactionCode,
    PayerAnswer,
    CreditParty,
    DebitParty,
  } = props;
  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = usePrint({
    content: () => componentRef.current,
  });

  return (
    <div className="flex h-[100svh] flex-col overflow-hidden pt-6">
      <h4 className="font-inter -mt-2 ml-4 pb-4 text-2xl font-bold text-neutral-darkest">
        Comprovante
      </h4>
      <div
        className="mb-6 flex w-full flex-col items-center overflow-hidden"
        ref={componentRef}
      >
        <ScrollArea className="flex h-full w-full flex-col px-6">
          <VerticalLogo />
          <div className="flex w-full flex-col items-center overflow-hidden">
            <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
              Transferência Pix
            </h5>
            <h5 className="mb-6 font-base text-sm/md font-bold text-primary-main">
              {/* Realizado */} {/* PRECISA SABER SE É ENTRADA OU SAÍDA */}
            </h5>
            <div className="flex h-full w-full flex-col overflow-hidden">
              {PayerAnswer && (
                <>
                  <hr className="h-[1px] w-full bg-neutral-light" />
                  <dl className="my-4 h-full w-full">
                    <dt className={definitionTerm()}>Mensagem</dt>
                    <dd
                      className={twMerge(definitionDetail(), "mt-1 text-start")}
                    >
                      {PayerAnswer}
                    </dd>
                  </dl>
                </>
              )}
              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Data da transferência</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatDate(TransactionDate)}
                </dd>
                <dt className={definitionTerm()}>Valor</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatCurrency(Amount)}
                </dd>
                <dt className={definitionTerm()}>Favorecido</dt>
                <dd className={twMerge(definitionDetail())}>
                  {CreditParty.Name}
                </dd>
                <dt className={definitionTerm()}>Instituição</dt>
                <dd className={twMerge(definitionDetail())}>
                  {CreditParty.BankName}
                </dd>
                <dt className={definitionTerm()}>Agência</dt>
                <dd className={twMerge(definitionDetail())}>
                  {CreditParty.BankBranchNumber}
                </dd>
                <dt className={definitionTerm()}>Conta</dt>
                <dd className={twMerge(definitionDetail())}>
                  {CreditParty.BankAccountNumber}
                </dd>
              </dl>

              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Pagador</dt>
                <dd className={twMerge(definitionDetail())}>
                  {DebitParty.Name}
                </dd>
              </dl>
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Instituição</dt>
                <dd className={twMerge(definitionDetail())}>
                  {DebitParty.BankName}
                </dd>
                <dt className={definitionTerm()}>Agência</dt>
                <dd className={twMerge(definitionDetail())}>
                  {DebitParty.BankBranchNumber}
                </dd>
                <dt className={definitionTerm()}>Conta</dt>
                <dd className={twMerge(definitionDetail())}>
                  {DebitParty.BankAccountNumber}
                </dd>
              </dl>

              <hr className="h-[1px] w-full bg-neutral-light" />
              <div className="col-span-2">
                <dl className="mt-4 w-full">
                  <dt className={definitionTerm()}>Id da Operação</dt>
                  <dd
                    className={twMerge(
                      definitionDetail(),
                      "break-all text-start",
                    )}
                  >
                    {TransactionCode}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </ScrollArea>
      </div>
      <Button.Root className="m-6 mt-auto" onClick={handlePrint}>
        <Button.Slot name="GeralPrint" />
        Imprimir comprovante
      </Button.Root>
    </div>
  );
};
