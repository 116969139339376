import {
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export type RequestError = AxiosError<unknown>;

export type QueryConfig<FetcherFnType extends (...args: unknown[]) => unknown> =
  UseQueryOptions<Awaited<ReturnType<FetcherFnType>>>;

export type MutationConfig<
  FetcherFnType extends (...args: unknown[]) => unknown,
> = UseMutationOptions<
  Awaited<ReturnType<FetcherFnType>>,
  RequestError,
  Parameters<FetcherFnType>[0]
>;
