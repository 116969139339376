import {
  API,
  formatCurrency,
  getReceiptRoutesData,
  getVertical,
  Notifications,
  queries,
  setHeader,
  useAccountStore,
  Vertical,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";

const routes = getReceiptRoutesData();

export {
  routes,
  Notifications,
  setHeader,
  queries,
  formatCurrency,
  API,
  useAccountStore,
  getVertical,
  Vertical,
};
