import { Notifications } from "@/utils";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { ParcelProps } from "./types";
import "./index.css";
import { Suspense } from "react";
import { ErrorFeedback } from "@/components/error-feedback";
import { LoadingReceipt } from "@/components/loading-receipt";
import { queryClient } from "@/lib";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const App = (props: ParcelProps) => {
  return (
    <BrowserRouter>
      <ErrorFeedback>
        <Suspense fallback={<LoadingReceipt />}>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            <Routes {...props} />
            <Notifications />
          </QueryClientProvider>
        </Suspense>
      </ErrorFeedback>
    </BrowserRouter>
  );
};

export default App;
